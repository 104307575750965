
import { createI18n } from "vue-i18n"
import eu from './locales/eu.json'
import es from './locales/es.json'
import en from './locales/en.json'
import fr from './locales/fr.json'
function loadLocaleMessages() {
    const locales = [{ eu: eu }, { es: es }, { en: en }, { fr: fr }]
    const messages = {}
    locales.forEach(lang => {
      const key = Object.keys(lang)
      messages[key] = lang[key] 
    })
    return messages
  }
  
  export default createI18n({
    locale: 'eu',
    fallbackLocale: 'eu',
    globalInjection: true,
    messages: loadLocaleMessages(),
    legacy: false
  })

