<template>
  <div class="herriak">
    <span class="borde"></span>
    <button :class="['accordion', { active: irekia != 0 }]" @click="irekiItxi">
      <span class="titulo"
        >{{ ingurua.titulo }}
        <span class="icono-txiki">
          <span>
            <img src="@/assets/icon-herria.svg" alt="" />
          </span>
        </span>
      </span>
    </button>
    <div
      class="panel"
      id="panelHerriak"
      :style="{ 'max-height': irekia + 'px' }"
    >
      <ul>
        <li v-for="herria in ingurua.herriak" :key="herria.id">
          <button
            type="button"
            class="btn-marcadores"
            @click="toggleHerria({ id: herria.id, activo: herria.activo })"
            :style="{ 'font-size': tamanoFuente + 'px' }"
          >
            <span
              :class="['fondo-marcadores', { active: herria.activo }]"
              style="background-color: #252525"
            ></span>

            <span class="icono-activo" v-if="herria.activo"
              ><img src="@/assets/herria2.svg" alt=""
            /></span>

            <span class="icono-inactivo" v-else>
              <span class="bola" style="border-color: #252525"></span>
              <span class="icono"
                ><img src="@/assets/herria1.svg" alt="" class="svg"
              /></span>
            </span>

            {{ herria.nombre }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  onMounted,
  inject,
  watchEffect,
} from "vue";
const props = defineProps({
  ingurua: {
    type: Object,
    required: true,
  },
});

const irekia = ref(0);

const tamanoFuente = inject("tamanoFuente");

const emits = defineEmits(["herria"]);

watchEffect(() => {
  if (tamanoFuente.value !== undefined) {
    ireki();
  }
});

function irekiItxi() {
  let elPanel = document.getElementById("panelHerriak");
  if (irekia.value == 0) irekia.value = elPanel.scrollHeight;
  else irekia.value = 0;
}
function ireki() {
  if (irekia.value != 0) {
    let elPanel = document.getElementById("panelHerriak");
    irekia.value = elPanel.scrollHeight + 40;
  }
}
function toggleHerria(cual) {
  emits("herria", { id: cual.id, activo: !cual.activo });
}

onMounted(() => {
  irekiItxi();
});
</script>
