import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import i18n from './i18n';

const app = createApp(App);
app.use(i18n);
app.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis"
        /* key: process.env.VUE_APP_MAPA_KEY */
    },
}).mount('#app');
