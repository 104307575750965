import {ref } from 'vue';

document.addEventListener("keydown", (event) => {

  if(event.code == "F11"){
    event.preventDefault();
  }

  });

const useFullScreen = () => {

    const full = ref(false);
    
    function isFullscreen(element) {
        return (
          (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) == element
        );
      }

    function requestFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullScreen) {
          element.msRequestFullScreen();
        }
        full.value = true;
      }

    function exitFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        full.value = false;
      }

      /* function fullScreen() {
       
        if (isFullscreen(app)) {
            exitFullscreen();
          } else {
            requestFullscreen(app);
          }
    } */


    return {
        full,
        isFullscreen,
        //fullScreen,
        requestFullscreen,
        exitFullscreen
    }
}

export default useFullScreen