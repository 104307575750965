<template>
    <div :class="['filtros', {active: abierto}]">
        <div class="lista-filtros">
            <Herriak :ingurua="ingurua" @herria="onHerria" />
            <Filtro v-for="item in filtroak" :key="item.id" :item="item" @atala="onAtala" />
        </div>
        <div class="botonera">
            <div class="btn-lateral"><button type="button" @click="filtroakEzabatu">{{ $t("filtroakezabatu") }}</button></div>
            <div class="btn-lateral"><button type="button" class="btn-emaitza" @click="emaitzaIkusi">{{ $t("emaitzaikusi") }} ({{props.zenbataktibo}})</button></div>
        </div>
    </div>
</template>
<script setup>
    import { defineProps, defineEmits } from 'vue'

    import Herriak from '@/components/Herriak.vue'
    import Filtro from '@/components/Filtro.vue'

    const props = defineProps({
        abierto: {
            type: String,
            required: true
        },
        ingurua: {
            type: Object,
            required: true
        },
        filtroak: {
            type: Array,
            required: true
        },
        zenbataktibo: {
            type: Number,
            required: true
        }
    });

    const emits = defineEmits(['atala','herria','filtroakezabatu', 'emaitzaikusi']);

    function onAtala(atala) {
        emits('atala', {id: atala.id, activo: atala.activo});
    }
    function onHerria(herria) {
        emits('herria', {id: herria.id, activo: herria.activo});
    }
    function filtroakEzabatu() {
        emits('filtroakezabatu');
    }
    function emaitzaIkusi() {
        emits('emaitzaikusi');
    }
</script>