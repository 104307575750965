<template>
  <div id="edukia" :class="{ active: irekita }">
    <div class="edukia">
      <button type="button" class="btn-edukia" @click="itxiEdukia"></button>
      <div id="info">
        <p
          class="mota"
          v-for="(item, index) in datos.zerbitzuak"
          :key="index"
          :style="'color:' + item.color"
        >
          <!-- <span><img :src="'/img/' + item.icono"></span> -->
          <span><img :src="item.icono" alt="" /></span>
          {{ item.zerbitzua }}
        </p>

        <h2>{{ datos.izena }}</h2>
        <p v-if="datos.mapa != ''">
          <a class="btn-map" :href="datos.mapa" target="_blank">{{
            $t("nolairitsi")
          }}</a>
        </p>
        <p class="helbidea">
          {{ datos.helbidea }}, {{ datos.cp
          }}<span>{{ datos.herria }} ({{ datos.probintzia }})</span>
        </p>
        <p v-if="datos.telefonoa != ''" class="telefonoa">
          {{ datos.telefonoa }}<span>{{ datos.mail }}</span>
        </p>
        <p v-if="datos.ordutegia != ''" class="ordutegi-titularra">ORDUTEGIA</p>
        <div
          v-if="datos.ordutegia != ''"
          class="ordutegia"
          v-html="datos.ordutegia"
        ></div>
        <p v-if="datos.web != ''">
          <a :href="datos.web" target="_blank" class="web">{{
            $t("webgunera")
          }}</a>
        </p>
        <p v-if="datos.erreserba != ''">
          <a :href="datos.erreserba" target="_blank" class="web">{{
            $t("erreserbara")
          }}</a>
        </p>
        <div
          v-if="datos.deskribapena != ''"
          class="deskribapena"
          v-html="datos.deskribapena"
        ></div>
      </div>
      <div
        id="carrusel"
        ref="carrusel"
        :class="{ full: full }"
        v-if="
          (datos.argazkiak && datos.argazkiak.length > 0) ||
          (datos.html && datos.html.length > 0)
        "
      >
        <div v-if="datos.argazkiak && datos.argazkiak.length > 0">
          <button
            type="button"
            class="full-screen"
            @click="fullScreen"
            v-if="!full"
          >
            <img src="@/assets/full-screen.svg" :alt="$t('zoomin')" />
          </button>
          <button type="button" class="full-screen" @click="fullScreen" v-else>
            <img src="@/assets/screen.svg" :alt="$t('zoomout')" />
          </button>

          <swiper
            id="swiper"
            :modules="[Navigation, Pagination, Zoom]"
            :slides-per-view="1"
            :space-between="50"
            navigation
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="(argazkia, index) in datos.argazkiak"
              :key="index"
            >
              <!-- <img :src="'img/' + argazkia" class="img-swiper"> -->
              <img :src="argazkia.img" class="img-swiper" :alt="argazkia.alt" />
            </swiper-slide>
          </swiper>
        </div>

        <div v-if="datos.html && datos.html.length > 0">
          <div v-for="(html, index) in datos.html" :key="index">
            <div v-html="html" class="html"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="fondoa" @click="itxiEdukia"></div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useI18n } from "vue-i18n";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";

import useFullScreen from "@/composables/useFullScreen";
const { full, isFullscreen, requestFullscreen, exitFullscreen } =
  useFullScreen();

const datos = ref({});

const carrusel = ref(null);

const { locale } = useI18n();

const props = defineProps({
  irekita: {
    type: Boolean,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["irekiEdukia", "itxiEdukia", "loading", "mezua"]);

watch(
  () => props.id,
  (newValue, oldValue) => {
    if (newValue != -2) getDatos(newValue); // Ha cambiado de idioma
  }
);

function irekiEdukia() {
  emits("irekiEdukia");
}

function itxiEdukia() {
  emits("itxiEdukia");
}
async function getDatos(id) {
  emits("loading", true);

  //try {

  //let response = await fetch("http://localhost:3000/edukia/8");
  /* let response = await fetch(
    "https://mapa.durangaldeaturismoa.loc/edukia.php?id=" +
      id +
      "&idioma=" +
      locale.value
  );*/
  let response = await fetch(
    "https://mapa.durangaldeaturismoa.eus/edukia.php?id=" +
      id +
      "&idioma=" +
      locale.value
  );
  if (response.ok) {
    let result = await response.json();
    datos.value = result;
    emits("loading", false);
    irekiEdukia();
  } else {
    console.log("error");
    console.log(response.status);
    emits("mezua");
  }
  /* } catch (error) {
        console.log(error);
        emits('mezua');
    } */
}

function onSlideChange() {
  console.log("slide change");
}

function fullScreen() {
  if (isFullscreen(carrusel.value)) {
    exitFullscreen();
  } else {
    requestFullscreen(carrusel.value);
  }
}
</script>
